import * as React from 'react';
import { navigate } from 'gatsby';
import ReactDOM from 'react-dom/client';
import { CartProvider } from 'react-use-cart';
import { Auth0Provider } from '@auth0/auth0-react';

import Layout from './src/components/layout';
import Transition from './src/components/popups/transition';
import OfflinePopup from './src/components/popups/offline';

import { ThemeProvider } from './src/components/context/themeContext';
import { CurrencyProvider } from './src/components/context/currencyContext';
import { LocaleProvider } from './src/components/context/localeContext';
import { CouponProvider } from './src/components/context/couponContext';

import './src/static/fonts/BalsamiqSans/styles.css';
import './src/static/fonts/Icons/styles.css';
import './src/static/css/theme/global.css';
import './src/static/css/slices/global.css';


export const onClientEntry = () => {
  const transitionOverlay = document.createElement('div');
  transitionOverlay.id = 'transition';
  const root = ReactDOM.createRoot(transitionOverlay);

  root.render(
    <React.StrictMode>
      <ThemeProvider>
        <Transition />
      </ThemeProvider>
    </React.StrictMode>
  );

  transitionOverlay.classList.add('hidden');
  document.body.appendChild(transitionOverlay);

  setTimeout(() => {
    transitionOverlay.classList.remove('hidden');
  }, 10);
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    
    root.render(element);
  };
};

export const onInitialClientRender = () => {
  const popupContainer = document.createElement('div');
  popupContainer.id = 'offline-mode';
  document.body.appendChild(popupContainer);

  const root = ReactDOM.createRoot(popupContainer);
  root.render(
    <React.StrictMode>
      <OfflinePopup />
    </React.StrictMode>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const { locales, id } = props.pageContext;

  if (locales && id) {
    return (
      <CartProvider>
        <LocaleProvider
         locales={locales} 
         remoteId={id}
        >
          <ThemeProvider>
            <CurrencyProvider>
              <Layout {...props}>{element}</Layout>
            </CurrencyProvider>
          </ThemeProvider>
        </LocaleProvider>
      </CartProvider>
    );
  };

  return (
    <CartProvider>
      <ThemeProvider>
        <CurrencyProvider>
          <Layout {...props}>{element}</Layout>
        </CurrencyProvider>
      </ThemeProvider>
    </CartProvider>
  );
};

export const wrapRootElement = ({ element }) => {
  const onRedirectCallback = (appState) => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const isPolish = browserLanguage.toLowerCase() === 'pl';
    const redirectPath = isPolish ? '/auth/pl/moje-konto' : '/auth/en/my-account';
  
    navigate(appState?.returnTo || redirectPath, { replace: true });
  };

  return (
    <CouponProvider>
      <Auth0Provider
       domain={process.env.GATSBY_AUTH_DOMAIN}
       clientId={process.env.GATSBY_AUTH_CLIENT_ID}
       cacheLocation='localstorage'
       useRefreshTokens={true}
       sessionCheckExpiryDays={30}
       onRedirectCallback={onRedirectCallback}
       authorizationParams={{
        redirect_uri: window.location.origin
       }}
      >
        {element}
      </Auth0Provider>
    </CouponProvider>
  );
};

export const disableCorePrefetching = () => true;
export const registerServiceWorker = () => true;
export const onServiceWorkerInstalled = () => console.log('Service Worker is ready');

export const onServiceWorkerUpdateReady = () => {
  const currentTime = Date.now();
  let lastUpdateTime = 0;
  
  if (currentTime - lastUpdateTime >= 5 * 60 * 1000) {
    window.location.reload(true);
    registerServiceWorker();
    
    lastUpdateTime = currentTime;
  };
};