import React, { PureComponent } from 'react';
import { offline } from 'static/css/theme/transition.css';

import { vars } from 'static/css/theme/vars.css';

const p = {
  margin: vars.space.none,
};


class OfflinePopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOnline: true,
      showOnlineMessage: false,
      lang: ''
    };
    this.handleOnlineStatusChange = this.handleOnlineStatusChange.bind(this);
    this.handleOfflineStatusChange = this.handleOfflineStatusChange.bind(this);
  };

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      window.addEventListener('online', this.handleOnlineStatusChange);
      window.addEventListener('offline', this.handleOfflineStatusChange);

      this.setLanguage();
    };
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showOnlineMessage && !prevState.showOnlineMessage) {
      this.timeoutId = setTimeout(() => {
        this.setState({ showOnlineMessage: false });
      }, 3500);
    };

    if (document.documentElement.lang !== this.state.lang) {
      this.setLanguage();
    };
  };

  componentWillUnmount() {
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      window.removeEventListener('online', this.handleOnlineStatusChange);
      window.removeEventListener('offline', this.handleOfflineStatusChange);
    };

    clearTimeout(this.timeoutId);
  };

  handleOnlineStatusChange() {
    this.setState({
      isOnline: true,
      showOnlineMessage: true,
    });
  };

  handleOfflineStatusChange() {
    this.setState({
      isOnline: false,
    });
  };

  setLanguage() {
    this.setState({
      lang: document.documentElement.lang || 'pl'
    });
  };

  render() {
    const {
      lang,
      isOnline,
      showOnlineMessage
    } = this.state;

    const offlineMessage = lang === 'pl' ? 'Jesteś w trybie offline' : 'You are in offline mode';
    const onlineMessage = lang === 'pl' ? 'Jesteś w trybie online' : 'You are in online mode';

    if (!isOnline || showOnlineMessage) {
      return (
        <div className={isOnline ? offline.online : offline.offline}>
          <p style={p}>{isOnline ? onlineMessage : offlineMessage}</p>
        </div>
      );
    };

    return null;
  };
};

export default OfflinePopup;