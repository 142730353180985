module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Niezłe Ziółko","short_name":"Niezłe Ziółko","start_url":"/pl","description":"Twój ulubiony sklep z artykułami konopnymi. Nasze produkty są naturalne oraz zawierają duże stężenie cbd. Sprawdź a na pewno nie pożałujesz!","categories":["health","lifestyle","shopping"],"lang":"pl","background_color":"#231F20","theme_color":"#231F20","theme_color_in_head":true,"icon":"src/images/favicon.png","display":"standalone","related_applications":[{"platform":"webapp","url":"https://niezleziolko.app/","id":"Niezłe Ziółko"}],"prefer_related_applications":false,"orientation":"natural","dir":"auto","scope":"/","crossOrigin":"use-credentials","cache_busting_mode":"none","localize":[{"name":"Niezłe Ziółko","description":"Your favorite store for hemp products. Our products are natural and contain a high concentration of cbd. Check it out and you certainly wont regret it!","lang":"en","start_url":"/en","scope":"/","shortcuts":[{"name":"Home page","url":"/en"},{"name":"Cart","url":"/en/cart"},{"name":"About us","url":"/en/about-us"},{"name":"Contact","url":"/en/contact"}]},{"name":"Niezłe Ziółko","description":"Twój ulubiony sklep z artykułami konopnymi. Nasze produkty są naturalne oraz zawierają duże stężenie cbd. Sprawdź a na pewno nie pożałujesz!","lang":"pl","start_url":"/pl","scope":"/","shortcuts":[{"name":"Strona główna","url":"/pl"},{"name":"Koszyk","url":"/pl/koszyk"},{"name":"O nas","url":"/pl/o-nas"},{"name":"Kontakt","url":"/pl/kontakt"}]}],"shortcuts":[{"name":"Strona główna","url":"/pl"},{"name":"Koszyk","url":"/pl/koszyk"},{"name":"O nas","url":"/pl/o-nas"},{"name":"Kontakt","url":"/pl/kontakt"}],"legacy":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.3_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/pl/*","/en/*","/auth/pl/*","/auth/en/*"],"appendScript":"/opt/buildhome/repo/src/static/js/sw.js","workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.6.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
