import * as React from 'react';

import { ThemeContext } from 'components/context/themeContext';

import favicon from 'images/favicon.webp';

import { 
  dark, 
  light
} from 'static/css/theme/vars.css';
import { transition } from 'static/css/theme/transition.css';


const Transition = () => {
  const themeContext = React.useContext(ThemeContext);
  const isDarkMode = React.useMemo(() => (themeContext ? themeContext.isDarkMode : false), [themeContext]);

  const size = 192;
  
  return (
    <div className={`transition-overlay ${isDarkMode ? dark : light}`}>
        <div className={transition.popup}>
            <div className={transition.image}>
                <img
                 src={favicon}
                 alt='Niezłe Ziółko'
                 width={size}
                 height={size}
                />
            </div>
        </div>
    </div>
  );
};

export default React.memo(Transition);