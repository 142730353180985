import * as React from 'react';
import { 
  Slice, 
  Script 
} from 'gatsby';

import { ThemeContext } from 'components/context/themeContext';

import { content } from 'static/css/theme/contents.css';
import { light, dark, responsiveTheme } from 'static/css/theme/vars.css';

const isBrowser = typeof window !== 'undefined';


export const Layout = props => {
  const { isDarkMode } = React.useContext(ThemeContext);

  React.useEffect(() => {
    if (isBrowser) {
      if (isDarkMode) {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      } else {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
      };
    };
  }, [isDarkMode]);

  return (
    <div className={isDarkMode ? dark : light}>
        <div className={responsiveTheme}>
            <Slice alias="header" isDarkMode={isDarkMode} allowEmpty />
            <main className={content.main}>{props.children}</main>
            <Slice alias="footer" isDarkMode={isDarkMode} allowEmpty />
        </div>
        {isBrowser && !window.zaraz && (
            <Script src='https://niezleziolko.app/cdn-cgi/zaraz/i.js' async />
        )}
    </div>
  );
};

export default React.memo(Layout);