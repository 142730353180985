import * as React from 'react';


const CouponDataContext = React.createContext();

export function useCouponData() {
  const context = React.useContext(CouponDataContext);
  
  if (!context) {
    throw new Error('useCouponData must be used within a CouponProvider');
  };

  return context;
};

export function useCoupon() {
  const { couponData, setCouponDataInContext } = useCouponData();

  const setCouponContext = (data) => {
    setCouponDataInContext(data);

    if (typeof window !== 'undefined') {
      localStorage.setItem('coupon-data', JSON.stringify(data));
    };
  };

  return { couponData, setCouponContext };
};

export function CouponProvider({ children, initialData }) {
  const [couponData, setCouponData] = React.useState(initialData);

  const setCouponDataInContext = (data) => {
    setCouponData(data);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (typeof window !== 'undefined') {
        const storedCouponData = localStorage.getItem('coupon-data');
        if (storedCouponData) {
          setCouponData(JSON.parse(storedCouponData));
        };
  
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('stamped_referral_code');
        const rewardsLauncher = urlParams.get('rewards-launcher');
  
        if (referralCode || rewardsLauncher === 'view-referrals-claim') {
          try {
            const body = {
              coupon_code: referralCode,
              coupon_type: 'Referral',
              date_expires: '2100-01-01'
            };

            const apiResponse = await fetch(process.env.GATSBY_COUPONS_ENDPOINT, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.GATSBY_COUPONS_AUTH}`
              },
              body: JSON.stringify(body)
            });
      
            const data = await apiResponse.json();
      
            if (apiResponse.ok) {
              console.log(data);
            };
          } catch (error) {
            console.error('Polecenie znajomego nie powiodło się:', error);
          };
          
          setCouponDataInContext({ code: referralCode });
          sessionStorage.setItem('referral-data', JSON.stringify({ code: referralCode }));
        };
      };
    };
  
    fetchData();
  }, []);
  
  return (
    <CouponDataContext.Provider value={{ couponData, setCouponDataInContext }}>
      {children}
    </CouponDataContext.Provider>
  );
};