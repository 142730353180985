import * as React from 'react';


const LocaleContext = React.createContext();

export function useLocale() {
  return React.useContext(LocaleContext);
};

export function LocaleProvider({ locales, remoteId, children }) {
  const [lang, setLang] = React.useState(locales);
  const [id, setId] = React.useState(remoteId);

  React.useEffect(() => {
    document.documentElement.setAttribute('lang', lang);
    document.documentElement.setAttribute('id', id);
  }, [lang, id]);

  React.useEffect(() => {
    setLang(locales);
    setId(remoteId);
  }, [remoteId, locales]);

  const contextValue = {
    locales,
    remoteId,
    lang,
    id,
    setLang,
    setId
  };

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};